<template>
  <div></div>
</template>

<script>
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useBreadcrumbStore } from "@/stores/breadcrumb"

export default {
  name: "Camera",
  beforeRouteLeave(to, from, next) {
    const cameraDialogStore = useCameraDialogStore()
    cameraDialogStore.dialog = false
    cameraDialogStore.isInline = false

    next()
  },
  async asyncData({ params }) {
    let cameraExid = params.camera || params.id

    const cameraDialogStore = useCameraDialogStore()
    await cameraDialogStore.selectCamera(cameraExid)
    cameraDialogStore.isInline = true
    const camera = cameraDialogStore.camera

    let breadcrumbs = [
      {
        name: camera.name,
        id: camera.exid,
        icon: "fa-camera",
      },
    ]

    if (params.project && params.camera && params.id) {
      const companyId = params.id
      const projectExid = params.project

      if (camera.projectName) {
        breadcrumbs = [
          {
            name: camera.projectName,
            id: projectExid,
            href: `/companies/${companyId}/${projectExid}`,
            icon: "fa-sitemap",
          },
          ...breadcrumbs,
        ]
      }

      breadcrumbs = [
        {
          icon: "fa-solid fa-home",
          href: "/",
        },
        {
          name: "Companies",
          href: "/companies",
        },
        {
          name: companyId,
          href: `/companies/${companyId}`,
          icon: "far fa-building",
        },
        ...breadcrumbs,
      ]
    }

    useBreadcrumbStore().breadcrumbs = breadcrumbs

    return {
      exid: cameraExid,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
  },
  mounted() {
    this.cameraDialogStore.openDialog({ camera: this.exid })
  },
}
</script>

<style lang="scss">
.camera-details {
  &__tabs {
    & > .v-tabs-items {
      max-height: calc(100vh - 100px) !important;
      height: calc(100vh - 100px) !important;
    }
  }
}
</style>
